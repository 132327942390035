import * as React from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const CorporatePage = () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    document.querySelector('button[type="submit"]').disabled = true;
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };
  return (
    <Layout>
      <SEO title="Corporate" />
      <main>
        <div
          className="wrapper-body"
          style={{ backgroundColor: '#fff' }}
          id="why-use-e1"
        >
          <section
            id="intro"
            style={{ height: '600px', backgroundImage: `url(${HeroImage})` }}
          >
            <div className="container">
              <div
                className="col-md-12"
                style={{ paddingTop: '120px', paddingBottom: '100px' }}
              >
                <h1>Corporate Bookings</h1>
              </div>
            </div>
          </section>

          <div className="padding-topbottom-50 main-text">
            <p>
              We have a special price structure for corporate bookings and
              pre-purchased booking. Please{' '}
              <Link to="/contact">contact us</Link> today for more information.
              You can also fill out the form below or{' '}
              <a
                href="/e1_transit_corporate_package.pdf"
                target="_blank"
              >
                download our brochure
              </a>
              .
            </p>
          </div>

          <div
            className="container"
            style={{ color: '#a2a2a2', marginBottom: '30px' }}
          >
            <div className="col-md-offset-2 col-md-8">
              <form
                name="contact-corporate"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className="form-horizontal"
              >
                <h2
                  data-drip-attribute="headline"
                  className="padding-topbottom-20 aligncenter"
                >
                  Learn More About E1 Corporate Partnerships
                </h2>
                <div data-drip-attribute="description"></div>
                <div className="form-group">
                  <label htmlFor="email" className="col-sm-2 control-label">
                    Email*
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      name="email"
                      required
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="first_name"
                    className="col-sm-2 control-label"
                  >
                    First Name*
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="first_name"
                      required
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="last_name" className="col-sm-2 control-label">
                    Last Name*
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="last_name"
                      required
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="organization"
                    className="col-sm-2 control-label"
                  >
                    Organization
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="organization"
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="phone_number"
                    className="col-sm-2 control-label"
                  >
                    Phone Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="phone_number"
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div
                  className="col-sm-offset-5 col-sm-8 padding-topbottom-20"
                  style={{ paddingLeft: '5px' }}
                >
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-success btn-lg"
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default CorporatePage;
